import React, { useEffect } from 'react'
import { useStore } from '../stores/store';
import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { ContactDetail } from '../models/ContactDetails';
import ContactDetailsGrid from '../components/ContactDetails/ContactDetailsGrid';
import ContactSendMessage from '../components/ContactDetails/ContactSendMessage';

const ContactDetailsPage = () => {
    const {commonStore:{setPreviousRoute,contactDetails}} = useStore();
    const [currentContact, setCurrentContact] = useState<ContactDetail | undefined>()
    const [showMessageBox, setShowMessageBox] = useState(false);

    useEffect(() => {
        setPreviousRoute(window.location.pathname);
    }, [])

     const resetShowMessage = () =>{
        setShowMessageBox(false);
        setCurrentContact(undefined);
    }
    const handleSendMessage = (detail: ContactDetail) =>{
        setCurrentContact(detail);
        setShowMessageBox(true);
    }

    return (
        <>
        <h2 className='page-header'>Contact Details</h2> 
        <ContactDetailsGrid details={contactDetails} sendMessage={handleSendMessage} />
        {showMessageBox && currentContact && (<ContactSendMessage contact={currentContact} resetDialog={resetShowMessage} showDialog={showMessageBox} />)}
        </>
  )
}

export default observer(ContactDetailsPage);
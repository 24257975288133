import { ErrorMessage, Form, Formik, FormikErrors } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { FaEnvelope } from 'react-icons/fa';
import { useStore } from '../../stores/store';
import InputGroup from './Form/InputGroup';
import * as Yup from 'yup';
import InfoBox from './Form/InfoBox';
export default observer(function ForgotPasswordBox(){
    const {commonStore, userStore} = useStore()
    const { currentLaundry } = commonStore;
    const {forgotPassword, requestSent, responseSuccess, setShowForgotPassword} = userStore;
    const [submitted, setSubmitted] = useState(false);
    const initValues = () =>({emailId: '', error: ''});
    const validationSchema = Yup.object({
        emailId: Yup.string().email().required().min(10).max(50)
    }) 
    const message = () => responseSuccess 
        ? "An email has been sent to your id with a password reset link. Please follow the instructions in the email to set a new password for your account. The link is valid for 24 hours only." 
        : "The email id does not exists in our database. Please try again with a valid email id.";
    const onSubmit = (values: {emailId: string, error: string}, setErrors: (errors: FormikErrors<{emailId: string, error: string}>) => void) =>{
        setSubmitted(true);
        return forgotPassword(values.emailId).catch((err) => setErrors({error: commonStore.getErrorMessage(err)}));
    }
    return(
        <div className="login-wrapper">
        <div className="logo">
            <img src= {`${currentLaundry?.appDocUrl}/Logo/${currentLaundry?.logo}`} alt={currentLaundry?.laundryName} />
            <h1 className="logo-title">{currentLaundry?.laundryName}</h1>
        </div>
        <div className="dialog-box login-box">
            <div className="login-box-title">
                <h2>Forgot Password</h2>
            </div>
            {requestSent && submitted && <InfoBox success={responseSuccess} message={message()} isPopup={true} handleOK={() => {setSubmitted(false); if(responseSuccess) setShowForgotPassword(false);}} />}            
            <Formik
                initialValues={initValues()}
                onSubmit={(values,{setErrors}) => onSubmit(values,setErrors)}
                validationSchema={validationSchema}
            >
                {({handleSubmit, isSubmitting, touched, dirty, errors}) =>  (
                    <>
                    <Form onSubmit={handleSubmit}>
                        <InputGroup name='emailId' placeholder='Email Id' type='email' icon={<FaEnvelope />} disabled={isSubmitting && dirty && !!touched} />
                        <ErrorMessage name='error' render={() => <span className='input-error'>{errors.error}</span>} />
                        <button type="submit" className="btn-fullwidth btn-default mb-20" disabled={isSubmitting && dirty && !!touched}>Submit</button>
                    </Form>
                    <a href='#'
                        onClick={
                            (e) => {
                                e.preventDefault();
                                setShowForgotPassword(false);
                            }
                        }>Back to Login</a>
                    </>
                )}
            </Formik>
        </div>
    </div>
    )
});
import { FieldArray, Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { DeliveryOrder } from "../../../models/DeliveryOrder";
import { useStore } from "../../../stores/store";
import LoadingComponent from "../LoadingComponent";
import InfoBox from "./InfoBox";
import InputQtyField from "./InputQtyField";
import RejectStockPrintableView from "../../RejectStock/RejectStockPrintableView";
import { useReactToPrint } from "react-to-print";
import { format } from "date-fns";


interface Props{
    printReport: boolean
    handleSubmit: (values: {stocks: DeliveryOrder[]}) => void;
}
export default observer(function StockForm({ printReport, handleSubmit }: Props){
    const {hotelPortalStore: { loadingStockList, stockList, responseReceived, responseSuccess}} = useStore();
    const [values, setValues] = useState<{stocks: DeliveryOrder[]}>({stocks: []});
    const [printValues, setPrintValues] = useState<DeliveryOrder[]>([])
    const [submitted, setSubmitted] = useState(false);
    const componentRef = useRef<HTMLDivElement>(null)

    const handlePrint = useReactToPrint({
            documentTitle: 'Reject Stock Report'
    })
    const message = () => {
        if(responseSuccess){
            if(printReport) handlePrint(null,()=>componentRef.current)
            return 'Your request has been submitted successfully!';
        }
        return 'An error occured while submitting your request. Please try again later.';
    }
    
    useEffect(() => {
        if(stockList)
            setValues({stocks:[...stockList]})
            setPrintValues([])
    }, [stockList])

    useEffect(()=>{
        if(submitted && responseReceived)
            window.scrollTo(0, 0);
    },[submitted, responseReceived])

    if(loadingStockList || values.stocks.length === 0)
        return <LoadingComponent message='Loading stocks... please wait!' />
    return (
        <>
        
        <div className="data-grid">
            <div className="data-grid-row header-row">
                <div className="data-grid-col-sm">Item Code</div>
                <div className="data-grid-col-lg">Item Name</div>
                <div className="data-grid-col-sm">Item Quantity</div>
            </div>
            <Formik
                initialValues={values!}
                enableReinitialize={true}
                onSubmit={(values) => {
                    setPrintValues(values.stocks.filter(v => v.quantity > 0))
                    handleSubmit(values);
                    setSubmitted(true);
                }}
            >
                {({values, touched, dirty, isSubmitting, resetForm}) =>  (
                    <>
                    { submitted && responseReceived && (<InfoBox message={message()} success={responseSuccess} handleOK={() =>{ responseSuccess && resetForm(); setSubmitted(false);}} />)}
                    <Form>
                        <FieldArray name='stocks'>
                        {
                            () => (
                                <div>
                                    {values.stocks && values.stocks.length > 0 && values.stocks.map((stock,i) => (
                                        <div key={i} className='data-grid-row'>
                                            <div className='data-grid-col-sm'>{stock.code}</div>
                                            <div className='data-grid-col-lg'>{stock.name}</div>
                                            <div className='data-grid-col-sm'>
                                                <input type="hidden" name={`stocks[${i}].stockAllocNo`} value={stock.stockAllocNo} />
                                                <InputQtyField name={`stocks[${i}].quantity`} disabled={isSubmitting} min={0} max={20}  />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )
                        }
                        </FieldArray>
                        <div className='data-grid-footer'>
                            <button disabled={isSubmitting && touched && dirty} type="submit" className="btn btn-default" >Send</button>
                        </div>
                    </Form>
                    </>
                )}
            </Formik>
        </div>
        <div style={{width: '100%', height: '100%', marginLeft:'10px',display: 'none'}}>
            <RejectStockPrintableView ref={componentRef} data={printValues} dateString={format(new Date(), 'dd/MM/yyyy')} />
        </div>
        </>
    )
})
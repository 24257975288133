import React, { useState } from "react";
import { TechSupportMessage } from "../models/techSupportMessage";
import { useStore } from "../stores/store";
import * as Yup from  'yup';
import { observer } from "mobx-react-lite";
import { Form, Formik } from "formik";
import Widget from "../components/common/Widget";
import InputMessageField from "../components/common/Form/InputMessageField";
import TextAreaMessageField from "../components/common/Form/TextAreaMessageField";
interface Props{
    width: number;
}
export default observer(function TechnicalSupportPage ({width}:Props) {

    const {userStore: {supportMessageSent, supportMessageSuccess, getTechnicalSupport, currentShop}} = useStore()
    const [messageValue, setMessageValue] = useState<TechSupportMessage>({name: '',emailId:'',message: '',contactNo:'', shopNo: currentShop?.shopNo || ''})
    const [submitted, setSubmitted] = useState(false);
    const message = () => (supportMessageSuccess ? 'Message sent successfully' : 'An error occured while sending message');

    const validationSchema = Yup.object({
        name: Yup.string().required().min(3).max(50),
        emailId: Yup.string().email().required().min(5).max(50),
        contactNo: Yup.string().required().min(10).max(15),
        message: Yup.string().required().min(5).max(500)
    });

    const handleSubmit = (values: TechSupportMessage) =>{
        getTechnicalSupport({...values, shopNo: currentShop?.shopNo||''});
        setSubmitted(true);
    }
    let widgetWidth: string;
    switch(width){
        case 1:
            widgetWidth='width-25';
            break;
        case 2:
            widgetWidth='width-50';
            break;
        case 3:
            widgetWidth='width-75';
            break;
        case 4:
            widgetWidth='width-100';
            break;
    }
    return(
        <div className='technical-support-wrapper'>
            <Formik
                    initialValues={messageValue}
                    validationSchema={validationSchema}
                    enableReinitialize={true}
                    onSubmit={handleSubmit} 
                >{({handleSubmit, resetForm, isSubmitting, touched, dirty}) => (
                    <Form className={`widget-form-wrapper ${widgetWidth}`} onSubmit={handleSubmit}>
                        <Widget 
                            width={4} 
                            noBorder={false} 
                            heading="Contact Technical Support" 
                            hasFooter={true} 
                            showInfoBox={submitted && supportMessageSent} 
                            message={message()} 
                            success={supportMessageSuccess}
                            defaultDisabled={isSubmitting && dirty && !!touched}
                            resetFunction={() =>{ resetForm(); setSubmitted(false);}}
                            >
                            <div className='widget-form'>
                                <InputMessageField name='name' fieldLabel='Name' placeholder='Your Name' disabled={isSubmitting && dirty && !!touched} />
                                <InputMessageField name='emailId' type='email' fieldLabel='Email Id' placeholder='yourname@domain.com' disabled={isSubmitting && dirty && !!touched} />
                                <InputMessageField name='contactNo' type='tel' fieldLabel='Contact No' placeholder='Contact No' disabled={isSubmitting && dirty && !!touched} />
                                <TextAreaMessageField name='message' fieldLabel='Message' placeholder='Your message...' fieldRows={8} disabled={isSubmitting && dirty && !!touched} />
                            </div>
                        </Widget>
                    </Form>

            )}
            </Formik>
        </div>

    )
    
})
import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import { ApiResponseStatus } from "../models/ApiResponse";
import { Laundry } from "../models/Laundry";
import { MenuModule, SubModule } from "../models/Module";
import { Manual } from "../models/Manual";
import { ContactDetail } from './../models/ContactDetails';
import { ErrorLogDto } from "../models/ErrorLogDto";
import { store } from "./store";

export default class CommonStore {
    isDesktop = false;
    isMobile = false;
    token: string | null = window.sessionStorage.getItem('ml_jwt');
    appInitialized = false;
    appLoaded = false;
    validLaundryCode = true;
    invalidLaundryCode = false;
    laundryUID: string | null = window.localStorage.getItem('ml_uid');
    currentLaundry: Laundry | undefined;
    previousRoute: string | null = window.sessionStorage.getItem('ml_pr');
    userModules: MenuModule[] = this.restoreUserModules();
    userSubModules: SubModule[] = [];
    docManuals:  Manual[] = [];
    docLoaded = false;
    contactDetails: ContactDetail[] = [];
    constructor(){
        makeAutoObservable(this);
        reaction(
            () => this.token,
            token => {
                if(token){
                    window.sessionStorage.setItem('ml_jwt',token);
                } else {
                    window.sessionStorage.removeItem('ml_jwt');
                }
            } 
        );
        reaction(
            () => this.laundryUID,
            uid => {
                if(uid){
                    window.localStorage.setItem('ml_uid',uid);
                } else {
                    window.localStorage.removeItem('ml_uid');
                }
            }
        )
        reaction(
            () => this.userModules,
            um => {
                if(um.length > 0)
                    window.sessionStorage.setItem('ml_um',JSON.stringify(um));
                else
                    window.sessionStorage.removeItem('ml_um');
            }
        )
        reaction(
            () => this.previousRoute,
            pr => {
                if(pr)
                    window.sessionStorage.setItem('ml_pr',pr);
                else
                    window.sessionStorage.removeItem('ml_pr');
            }
        )
    }

    private restoreUserModules(){
        const um = window.sessionStorage.getItem('ml_um');
        if(um)
            return JSON.parse(um);
        else
            return [];
    }
    getErrorMessage(error: unknown) {
        if (error instanceof Error) return error.message
        return String(error)
    }
    resetUserModules = () =>{
        this.userModules = [];
        window.sessionStorage.removeItem('ml_um');
    }
    setPreviousRoute = (pRoute: string) => {
        if(pRoute !== '/')
        if(this.previousRoute !== pRoute)
            this.previousRoute = pRoute;
    }
    clearPreviousRoute = () => {
        this.previousRoute = null;
        window.sessionStorage.removeItem('ml_pr');
    }

    setIsDesktop = () => {
        runInAction(() => {
            this.isDesktop = true;
            this.isMobile = false;
        })
    }
    setIsMobile = () => {
        runInAction(() => {
            this.isDesktop = false;
            this.isMobile = true;
        })
    }
   
    setToken = (token: string | null) => {
        this.token = token;
    }

    setAppLoaded = (loaded: boolean = true) => {
        this.appLoaded = loaded;
    }

    setAppInitialized = (initialized: boolean = true) => {
        this.appInitialized = initialized;
    }
    setInvalidLaundryCode = () => {
        this.invalidLaundryCode = true;
    }
    
    setLaundryUID = (uid: string) => {
        if(this.laundryUID !== uid)
            this.laundryUID = uid;
    }
    addErrorLog = async (functionName:string, error: unknown) => {
        const log: ErrorLogDto = {
            key: process.env.REACT_APP_LOG_KEY ?? '',
            logDate: new Date(),
            appName: 'LAMS Webportal',
            customerId: store.userStore.currentShop?.shopNo ?? '',
            functionName: functionName,
            error: this.getErrorMessage(error)
        };
        await agent.ErrorLogWS.addLog(log);
    }

    loadLaundry = async () => {
        this.setAppInitialized(false);
        try{
            if(this.laundryUID){
                const response = await agent.AppWS.getLaundry(this.laundryUID);
                if(response && response.status === ApiResponseStatus.responseOK){
                    if(response.result){
                        runInAction(()=> this.currentLaundry = response.result);
                        agent.setShopApi(response.result.apiEndPoint);
                    }
                    else
                        this.setInvalidLaundryCode();
               }
            }
        } catch(error){
            this.setInvalidLaundryCode();
            this.addErrorLog("commonStore.loadLaundry",error)
        } finally {
            this.setAppInitialized();
        }
    }

    checkLaundryUID = async (uid: string) => {
        this.setAppInitialized(false);
        try{
            if(uid.length > 0){
                const response = await agent.AppWS.checkLaundryUID(uid);
                if(response && response.status === ApiResponseStatus.responseOK){
                    runInAction(()=>{
                        this.validLaundryCode = response.result;
                        this.invalidLaundryCode = !response.result;
                    })
                }
                if(this.validLaundryCode){
                    if(this.laundryUID !== uid){
                        this.setLaundryUID(uid);
                        this.loadLaundry();
                    }
                }
            } else {
                this.setInvalidLaundryCode();
            }
        } catch (error){
            this.addErrorLog("commonStore.checkLaundryUID",error)
        } finally {
            this.setAppInitialized();
        }
    }

    loadUserModules = async () => {
        this.setAppLoaded(false);
        try{
            const response = await agent.Account.getmodules();
            if(response && response.status === ApiResponseStatus.responseOK){
                runInAction(() => {
                    if(this.userModules.length === 0)
                        this.userModules = [...response.result];
                })
            }
        }
        catch(error){
            this.addErrorLog("commonStore.loadUserModules",error);
        } finally {
            this.setAppLoaded();
        }
    }

    loadSubModules = async (action: string) => {
        if(this.userModules){
            const module = this.userModules.find(um => um.action === action);
            if(module)
                runInAction(() => this.userSubModules = [...module.subModules])
        }
    }

    clearSubModules = () =>{
        this.userSubModules = [];
    }
   
    loadManuals = async () =>{
        this.docLoaded = false;
        try{
            const response = await agent.Documents.getManuals();
            if(response && response.status === ApiResponseStatus.responseOK){
                runInAction(() => this.docManuals = response.result);
            }
        }
        catch(error){
            this.addErrorLog("commonStore.loadManuals",error);
        } finally {
            runInAction(() => this.docLoaded = true);
        }

    }

    loadContacts = async () =>{
        try{
            const response = await agent.Contacts.getContacts();
            if(response && response.status === ApiResponseStatus.responseOK){
                runInAction(() => this.contactDetails = response.result)
            }
        }
        catch(error){
            this.addErrorLog("commonStore.loadContacts",error);
        }
    }
}